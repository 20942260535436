<template>
  <div class="banner-wrapper" :style="{height: clientHeight + 'px'}">
    <div class="banner_txt">
      <p class="p1">{{ $t("L_MAIN.BANNER_0001") }}</p>
      <p class="p2">{{ $t("L_MAIN.BANNER_0002") }}</p>
      <i @click="showVideo"></i>
    </div>
    <div class="banner_merits"  :class="language === 'en' ? 'english' : 'chinese'">
      <ul>
        <li v-for="(item, index) in meritsList" :key="index">
          <span class="img" :style="{background: language === 'en' ? getStyle2(item.image_en) : getStyle1(item.image), backgroundSize: 'contain'}"></span>
          <div class="merit">
            <p>{{item.name}}</p>
            <p>{{item.content}}</p>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        :close-on-click-modal=false
        :close-on-press-escape=false
        :lock-scroll=false
        width="700"
        :before-close="handleClose"
        >
      <video  autoplay="autoplay" controls width="700" height="396" ref="video" poster="../../../assets/image/pc/index/banner/poster.jpg">
        <source src="https://app.larkview.cn/portal/data/video/video_tutorial.mp4" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/index/banner/duo.png");
let image2 = require("@/assets/image/pc/index/banner/kuai.png");
let image3 = require("@/assets/image/pc/index/banner/hao.png");
let image4 = require("@/assets/image/pc/index/banner/sheng.png");
let image5 = require("@/assets/image/pc/en/index/banner/duo.png");
let image6 = require("@/assets/image/pc/en/index/banner/kuai.png");
let image7 = require("@/assets/image/pc/en/index/banner/hao.png");
let image8 = require("@/assets/image/pc/en/index/banner/sheng.png");
export default {
  name: "banner",
  data() {
    return {
      clientHeight: 0,
      meritsList: [
        {
          image: image1,
          image_en: image5,
          name: this.$t("L_MAIN.BANNER_0003"),
          content: this.$t("L_MAIN.BANNER_0004")
        },
        {
          image: image2,
          image_en: image6,
          name: this.$t("L_MAIN.BANNER_0005"),
          content: this.$t("L_MAIN.BANNER_0006")
        },
        {
          image: image3,
          image_en: image7,
          name: this.$t("L_MAIN.BANNER_0007"),
          content: this.$t("L_MAIN.BANNER_0008")
        },
        {
          image: image4,
          image_en: image8,
          name: this.$t("L_MAIN.BANNER_0009"),
          content: this.$t("L_MAIN.BANNER_0010")
        }
      ],
      dialogVisible: false
    }
  },
  computed: {
    language() {
      let language = this.$i18n.locale;
      return language;
    },
  },
  mounted() {
    // 获取浏览器可视区域高度
    this.clientHeight = document.documentElement.clientHeight; // document.body.clientWidth;
    window.onresize = function temp() { // 在窗口或框架被调整大小时触发
      this.clientHeight = document.documentElement.clientHeight;
    };
  },
  methods: {
    getStyle1(image) {
      return "url('"+image+"') center center no-repeat"
    },
    getStyle2(image) {
      return "url('"+image+"') center center no-repeat"
    },
    showVideo() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.video.currentTime = 0;
      })
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/banner.scss";
</style>